frappe.provide('erpnext');
// add toolbar icon
$(document).bind('toolbar_setup', function () {
    // $('.navbar-home').html('<img class="erpnext-icon" src="' +
    //      frappe.urllib.get_base_url() + '/assets/pdr/images/favicon.png"/>');
    // //frappe.set_route("modules", "PDR Management");

    

    // if (frappe.user.has_role("PDR Technician") && frappe.session.user != "Administrator"){ 
    //     $('.navbar-home').attr("href", "desk#pdr_menu");
    // } else {
    //     $('.navbar-home').attr("href", "#modules/PDR Management");   
    // }

});


